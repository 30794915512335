import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
import leslieMillerImage from '../images/lieutenant-leslie-h-miller.jpg';
import millerFarmImage from '../images/miller-farm-1926.jpg';
import montyImage from '../images/monty-beside-vimyoak.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Figure = makeShortcode("Figure");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
  <Column colSpan={8} mdxType="Column">
    <p>
      Lieutenant Leslie H. Miller, a soldier with the Canadian Expeditionary
      Force (1914-1919), sent acorns home to Canada that he had gathered after
      the Battle of Vimy Ridge in April 1917.
    </p>
    <p>
      These acorns are now large majestic oaks that are growing on his former
      farm, today the home of the Scarborough Chinese Baptist Church, in
      Scarborough Ontario.
    </p>
    <p>
      ‘World War One’ wiped out all but one native oak known to survive in the
      area today. The foresight of Lieutenant Miller allows us the possibility
      to repatriate young “Vimy Oak” saplings to their original territory of
      Vimy Ridge one hundred years later.
    </p>
    <p>
      Leslie Miller was born October 5, 1889 on the family farm in Scarborough,
      Ontario. His Canadian family was of German origin. They immigrated to the
      United States of America around 1740. In 1792, while the family lived in
      Philadelphia, Pennsylvania, Nicholas Miller, Leslie Miller’s
      great-great-grandfather, was invited by Lieutenant Governor John Graves
      Simcoe to come to Upper Canada (present day Ontario) to build the
      government’s first industrial facility – a sawmill – on the Humber River
      (today known as “The Old Mill”). It was completed in 1793, assisted by the
      Queen’s Rangers. Over the years, the Miller family descendants have
      contributed to the foundation of Canadian society in the fields of
      industry, business and politics.
    </p>
  </Column>
  <Column colSpan={4} mdxType="Column">
    <Figure image={leslieMillerImage} width={300} mdxType="Figure">
      Lieutenant Leslie H. Miller.
    </Figure>
  </Column>
    </Row>
    <hr></hr>
    <Row mdxType="Row">
  <Column colSpan={4} mdxType="Column">
    <Figure image={millerFarmImage} width={350} mdxType="Figure">
      1926 view north from south boundary: Leslie’s newly built house. “Vimy
      Oaks” woodlot just beginning to grow west of chicken coops.
    </Figure>
  </Column>
  <Column colSpan={8} mdxType="Column">
    <p>
      Leslie Miller was a learned man who loved people, nature and languages
      (French, German, Spanish, Greek, Hebrew and English). His goal was to be a
      teacher. Following his studies at the University of Toronto he moved to
      Saskatchewan to teach. While there in October 1914, he enlisted as a
      private in the 24th Border Horse of Weyburn, Saskatchewan. He subsequently
      joined the Signal Corps and was assigned to the 5th Battalion to go
      overseas. The severe winter of 1915 delayed his arrival at Shorncliffe
      army camp in England until April. There, he underwent six months of
      intensive signals training before crossing the Channel to Le Havre,
      France, in October 1915. He entered the line on the France - Belgium
      frontier just south of Messines.
    </p>
    <p>
      Leslie Miller spent most of his war years in the Nord-Pas de Calais region
      of France. He rose through the ranks and received a field commission as
      1st Lieutenant in 1918. In the Signal Corps, he mastered the means of
      communication (semaphore, Morse code, even pigeons) and trained other
      soldiers in these same skills. He deployed wireless technology and the
      newly developed line based Morse Fullerphones that were immune from
      overhearing by the enemy. He established communication networks and
      listening stations traversing the battlefields at night using his
      knowledge of astronomy to guide him. His mastery of languages ​​was
      particularly important in his intercepts and interpretation of German
      wireless messages for which he received commendations.
    </p>
  </Column>
    </Row>
    <hr></hr>
    <Row mdxType="Row">
  <Column colSpan={8} mdxType="Column">
    <p>
      Although present at many of the horrifying battles of World War One,
      Leslie Miller’s war diary is largely devoted to the friendships he
      cultivated with local French families, his fellow soldiers, his
      interaction with German prisoners and the nature he discovered around him.
    </p>
    <p>
      Upon returning to Canada, for health reasons, Lieutenant Miller was unable
      to resume his teaching career. His father gave him 24 acres of the family
      farm where his “Vimy Oaks” were growing. He replanted the oaks as part of
      his woodlot that also included sugar maples, black walnut and other
      hardwoods. Leslie named his land “The Vimy Oaks”. While working his fruit
      and vegetable farm he used his skill in languages to reach out to new
      Canadians, giving them garden plots to grow their own produce. He also
      mentored many young people whom he employed part time on his farm, passing
      on his values and his love of nature and the humanities.
    </p>
    <p>
      Ten of the original oaks soar into the sky today. Descendants of these
      great symbolic trees were repatriated to Vimy Ridge in November 2018 to
      create living memorials to honour those who fought in the Great War,
      connecting modern Canada and modern France and reaffirming our comradeship
      with France and her people.
    </p>
  </Column>
  <Column colSpan={4} mdxType="Column">
    <Figure image={montyImage} width={350} mdxType="Figure">
      Monty McDonald, a friend of Leslie Miller, one of the young people Leslie
      mentored, beside one of the “Vimy Oaks” still standing one hundred years
      later on the grounds of the Scarborough Chinese Baptist Church.
    </Figure>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      